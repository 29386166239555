/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import * as React from "react"
import { GatsbyBrowser } from "gatsby"
import { CookiesProvider } from "react-cookie"
import InterfaceContextProvider from "./src/context/UserInterfaceContext"

// import fonts
import "@fontsource/lato/300.css"
import "@fontsource/lato/400.css"
import "@fontsource/lato/400-italic.css"
import "@fontsource/lato/700.css"
import "@fontsource/lato/900.css"

// import AuthContextProvider from "./src/context/AuthContext"
import PtsCartContextProvider from "./src/context/PtsCartContext"

// import "./src/styles/legacy/js_composer.min.css"
import "./src/styles/legacy/block-editor-style.min.css"
// import "./src/styles/legacy/font-awesome.min.css"
// import "./src/styles/legacy/bootstrap.min.css"
// import "./src/styles/legacy/gc.min.css"
import "./src/styles/legacy/blocks.style.build.css"
import "./src/styles/global.css"

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => (
  <CookiesProvider>
    {/* <AuthContextProvider> */}
    <InterfaceContextProvider>
      <PtsCartContextProvider>{element}</PtsCartContextProvider>
    </InterfaceContextProvider>
    {/* </AuthContextProvider> */}
  </CookiesProvider>
)

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = () => {
  // const { showAwkward, setShowAwkward } = React.useContext(UserInterfaceContext)
  console.log(`route updated`)
  window.georgian_onRouteUpdate()
}

export const onRouteUpdateDelayed: GatsbyBrowser["onRouteUpdateDelayed"] =
  () => {
    // const { showAwkward, setShowAwkward } = React.useContext(UserInterfaceContext)
    console.log(`route update delayed`)
    window.georgian_onRouteUpdateDelayed()
  }

export { onRenderBody } from "./gatsby-ssr"
