/**
 * Implement Gatsby's SSR (Server Side Rendering) APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/ssr-apis/
 */
import * as React from "react"
import { GatsbySSR } from "gatsby"

import InterfaceContextProvider from "./src/context/UserInterfaceContext"
// import { AuthContextProviderSsr } from "./src/context/AuthContext"
import PtsCartContextProvider from "./src/context/PtsCartContext"

// import fonts
import "@fontsource/lato/300.css"
import "@fontsource/lato/400.css"
import "@fontsource/lato/400-italic.css"
import "@fontsource/lato/700.css"
import "@fontsource/lato/900.css"

// import "./src/styles/legacy/js_composer.min.css"
import "./src/styles/legacy/block-editor-style.min.css"
// import "./src/styles/legacy/font-awesome.min.css"
// import "./src/styles/legacy/bootstrap.min.css"
// import "./src/styles/legacy/gc.min.css"
import "./src/styles/legacy/blocks.style.build.css"
import "./src/styles/global.css"
import { CookiesProvider } from "react-cookie"

export const wrapPageElement: GatsbySSR["wrapPageElement"] = ({ element }) => (
  <CookiesProvider>
    {/* <AuthContextProviderSsr> */}
    <InterfaceContextProvider>
      <PtsCartContextProvider>{element}</PtsCartContextProvider>
    </InterfaceContextProvider>
    {/* </AuthContextProviderSsr> */}
  </CookiesProvider>
)

const HtmlAttributes = {
  lang: `en`,
}

export const onRenderBody: GatsbySSR["onRenderBody"] = ({
  setHtmlAttributes,
  setHeadComponents,
}) => {
  // eslint-disable-next-line @babel/no-unused-expressions
  setHtmlAttributes(HtmlAttributes),
    setHeadComponents([
      <script
        key="stape-loader"
        dangerouslySetInnerHTML={{
          __html: `
          (function() {
            var stapeScript = document.createElement('script');
            stapeScript.async = true;
            stapeScript.src = '(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s);j.async=true;j.src="https://load.ss.georgiancollege.ca/fbkzyintz.js?"+i;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','d1idmt=aWQ9R1RNLU5YTVY0QkM%3D&page=3');'; 
            document.head.appendChild(stapeScript);
          })();
        `,
        }}
      />,
    ])
}
